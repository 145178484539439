import { useState } from "react";
import clsx from "clsx";

import Section from "components/common/Section";
import ButtonGhost from "components/common/ButtonGhost";

const roadmapData = [
  {
    stepNum: "01",
    img: "/assets/landing/roadmap-1.png",
    title: "Discovery",
    description:
      "We schedule a free video call with you to understand your goals and expectations. After the call, we provide you with the next steps, a rough price and duration estimate for development.",
  },
  {
    stepNum: "02",
    img: "/assets/landing/roadmap-2.png",
    title: "Analysis & Product Design",
    description:
      "We schedule a free video call with you to understand your goals and expectations. After the call, we provide you with the next steps, a rough price and duration estimate for development.",
  },
  {
    stepNum: "03",
    img: "/assets/landing/roadmap-3.png",
    title: "Software Development",
    description:
      "We schedule a free video call with you to understand your goals and expectations. After the call, we provide you with the next steps, a rough price and duration estimate for development.",
  },
  {
    stepNum: "04",
    img: "/assets/landing/roadmap-4.png",
    title: "Support",
    description:
      "We schedule a free video call with you to understand your goals and expectations. After the call, we provide you with the next steps, a rough price and duration estimate for development.",
  },
];

const Roadmap = () => {
  const [selectedStep, setSelectedStep] = useState(0);

  return (
    <Section className="pt-[160px]" leftLine rightLine topLine topCrosses>
      <div className="grid xl:grid-cols-2 gap-20 2xl:gap-[112px]">
        <div className="relative">
          {/* <img
            className="absolute w-[50%] opacity-50"
            src="/assets/landing/rodmap-bg.png"
            alt="roadmap"
          /> */}
          <img
            className="mx-auto xl:mx-0"
            src="/assets/landing/roadmap-bg.svg"
            alt="roadmap"
          />
          <img
            className="w-[80%] sm:w-[400px] xl:w-[80%] absolute left-1/2 xl:left-[calc(50%-7%)] -translate-x-1/2 top-1/2 -translate-y-1/2"
            src={roadmapData[selectedStep].img}
            alt=""
          />
        </div>

        <div className="flex items-center">
          <div>
            <div className="flex items-center gap-3">
              <span className="text-clrx text-xs">[</span>
              <span className="text-neutral-3 text-xs font-space">
                STEP {roadmapData[selectedStep].stepNum}
              </span>
              <span className="text-clrx text-xs">]</span>
            </div>

            <h2 className="my-6">{roadmapData[selectedStep].title}</h2>

            <p className="text-neutral-3">
              {roadmapData[selectedStep].description}
            </p>

            <div className="mt-10">
              <ButtonGhost>CONNECT NOW</ButtonGhost>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 grid gap-6 sm:grid-cols-2 xl:grid-cols-4">
        {roadmapData.map((el, idx) => {
          return (
            <div
              key={"roadmap-step-" + idx}
              className="cursor-pointer"
              onClick={() => setSelectedStep(idx)}
            >
              <div
                className={clsx(
                  "w-full h-[2px] bg-[#d9d9d9] rounded transition-all",
                  idx === selectedStep ? "opacity-100" : "opacity-10"
                )}
              >
                <div
                  className={clsx(
                    idx === selectedStep ? "bg-clrx" : "bg-white",
                    "w-1/2 h-full transition-all"
                  )}
                ></div>
              </div>

              <div className="mt-10">
                <div className="text-neutral-4 text-xs">{el.stepNum}.</div>
                <div className="mt-1 text-2xl">{el.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default Roadmap;
