import { Splide, SplideSlide } from "@splidejs/react-splide";
import clsx from "clsx";

import Section from "components/common/Section";
import ServiceCard from "components/common/ServiceCard";

const services = [
  {
    title: "Custom Software Development",
    description:
      "Launch custom software projects - Enterprise Software, MVPs, SAAS, Internal Systems & other web apps in 4-12 weeks.",
  },
  {
    title: "Native App Development",
    description:
      "Launch a native iOS & Android app built to your exact requirements with lightning speed.",
  },
  {
    title: "Product Design",
    description:
      "Full Figma designs and a clickable prototype of your software, so you know exactly how it will look and feel.",
  },
  {
    title: "Analysis & Scoping",
    description:
      "Determine the necessary features, database diagrams, user stories and flows of your product to simplify development.",
  },
  {
    title: "Custom Software Development",
    description:
      "Launch custom software projects - Enterprise Software, MVPs, SAAS, Internal Systems & other web apps in 4-12 weeks.",
  },
];

const Services = ({ noPt, pb }) => {
  return (
    <>
      <Section className={clsx(!noPt && "pt-[160px]")} leftLine rightLine>
        <h2 className="text-center">Our Services</h2>
      </Section>

      <div
        className={clsx(
          "overflow-hidden 2xl:px-0 pl-5 md:pl-10 relative",
          pb && "pb-[160px]"
        )}
      >
        <div className="absolute z-20 left-5 md:left-10 top-0 h-full w-[1px] border-l border-solid border-stroke-1"></div>

        <div className="absolute z-20 right-5 md:right-10 top-0 h-full w-[1px] border-r border-solid border-stroke-1"></div>

        <div className="mt-20 ml-auto max-w-[calc(1280px+(100vw-1280px)/2)]">
          <div className="">
            <Splide
              options={{
                arrows: false,
                gap: 48,
                autoWidth: true,
                type: "loop",
                // perPage: 3.75,
              }}
            >
              {services.map((el, idx) => {
                return (
                  <SplideSlide key={"service-" + idx}>
                    <div className="h-full w-[calc(100vw-40px)] sm:w-[384px]">
                      <ServiceCard {...el} idx={idx} />
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
