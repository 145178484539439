const ButtonPrimary = ({ children, ...rest }) => {
  return (
    <button
      className="flex items-center transition-all hover:-translate-y-[2px]"
      {...rest}
    >
      <svg
        width="20"
        height="44"
        viewBox="0 0 20 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 8V36C0 40.4183 3.18375 44 7.11111 44H20V0H7.11111C3.18375 0 0 3.58172 0 8Z"
          fill="white"
        />
      </svg>

      <span className="border-t-[3px] bg-white inline-flex items-center h-11 px-2 text-neutral-8 font-mono text-xs font-bold">
        {children}
      </span>

      <svg
        width="20"
        height="44"
        viewBox="0 0 20 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2091 10.4692L7.18698 1.85545C5.79211 0.656586 4.03356 0 2.21748 0H0V44H5.02817H12.2396C16.5255 44 20 40.4183 20 36V16.6138C20 14.2402 18.9776 11.9892 17.2091 10.4692Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default ButtonPrimary;
