import ButtonGhost from "components/common/ButtonGhost";
import Section from "components/common/Section";
import Card from "./Card";
import clsx from "clsx";

const projects = [
  {
    logo: "/assets/landing/client.png",
    img: "/assets/landing/project-1.png",
    title: "Gamified Weight Loss Platform",
    description:
      "Cutting Season is a platform where users can participate in games by betting on how much weight they can lose in a certain amount of time.",
  },
  {
    logo: "/assets/landing/client.png",
    img: "/assets/landing/project-2.png",
    title: "All-in-one ERP with GPS capability",
    description:
      "Open Infra is an internal tool for a communications operator that builds, manages and develops fibre and electrical infrastructure for households, companies and municipalities.",
  },
  {
    logo: "/assets/landing/client.png",
    img: "/assets/landing/project-3.png",
    title: "Internal insurance company dashboard",
    description:
      "Read about how we built an internal dashboard for an insurance company with No Code, using Bubble.io. The dashboard is used to manage sales more conveniently.",
  },
  {
    logo: "/assets/landing/client.png",
    img: "/assets/landing/project-4.png",
    title: "Social Network for Dogs in New York",
    description:
      "Read about Mond, a No Code native mobile app for iOS we built using Bubble. Mond is a social network for dogs in New York.",
  },
];

const Projects = ({ withLogo, withReadMoreButton }) => {
  return (
    <Section leftLine rightLine className="py-24 md:py-[160px]">
      {!withReadMoreButton && (
        <>
          <img
            className="absolute left-0 top-0"
            src="/assets/landing/bg-projects.png"
            alt="projects-bg"
          />

          <div className="text-center relative z-10">
            <div className="flex justify-center items-center gap-3">
              <span className="text-clrx text-xs">[</span>
              <span className="text-neutral-3 text-xs font-space">
                OUR WORK
              </span>
              <span className="text-clrx text-xs">]</span>
            </div>

            <h2 className="mt-4">What we're working on</h2>
          </div>
        </>
      )}

      <div
        className={clsx(
          "lg:pb-24 relative z-10",
          !withReadMoreButton && "mt-20"
        )}
      >
        <div className="grid lg:grid-cols-2 gap-6">
          {projects.map((el, idx) => {
            return (
              <Card
                key={"project-" + idx}
                idx={idx}
                {...el}
                withLogo={withLogo}
                withReadMoreButton={withReadMoreButton}
              />
            );
          })}
        </div>
      </div>

      {!withReadMoreButton && (
        <div className="mt-20 flex justify-center relative z-10">
          <ButtonGhost>OUR ROADMAP</ButtonGhost>
        </div>
      )}
    </Section>
  );
};

export default Projects;
