const ButtonGhost = ({ children, ...rest }) => {
  return (
    <button
      className="flex items-center transition-all hover:-translate-y-[2px]"
      {...rest}
    >
      <svg
        width="21"
        height="44"
        viewBox="0 0 21 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 43H8.11111C4.18375 43 1 39.5811 1 35.3636V8.63636C1 4.41892 4.18375 1 8.11111 1H21"
          stroke="#FF9203"
          stroke-width="2"
        />
      </svg>

      <span className="border-t-[2px] border-b-[2px] border-solid border-clrx inline-flex items-center h-11 px-2 text-white font-mono text-xs font-bold">
        {children}
      </span>

      <svg
        width="21"
        height="44"
        viewBox="0 0 21 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 43H5.02817H12.2396C16.5255 43 20 39.5811 20 35.3636V16.8586C20 14.5929 18.9776 12.4442 17.2091 10.9933L7.18698 2.77111C5.79211 1.62674 4.03356 1 2.21748 1H0"
          stroke="#FF9203"
          stroke-width="2"
        />
      </svg>
    </button>
  );
};

export default ButtonGhost;
