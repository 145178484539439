import clsx from "clsx";

const Section = ({
  topLine,
  leftLine,
  rightLine,
  topCrosses,
  bottomLine,
  bottomCrosses,
  className,
  rootClassName,
  maxW,
  children,
}) => {
  return (
    <div className={clsx(rootClassName, "px-5 md:px-10 relative")}>
      {topLine && (
        <div className="absolute top-0 left-5 md:left-10 right-5 md:right-10 z-20 h-[1px] border-t border-solid border-stroke-1"></div>
      )}
      {bottomLine && (
        <div className="absolute bottom-0 left-5 md:left-10 right-5 md:right-10 z-20 h-[1px] border-b border-solid border-stroke-1"></div>
      )}
      {leftLine && (
        <div className="absolute z-20 left-5 md:left-10 top-0 h-full w-[1px] border-l border-solid border-stroke-1"></div>
      )}
      {rightLine && (
        <div className="absolute z-20 right-5 md:right-10 top-0 h-full w-[1px] border-r border-solid border-stroke-1"></div>
      )}
      {topCrosses && (
        <>
          <img
            className="absolute z-30 left-[calc(20px-5.5px)] md:left-[calc(40px-5.5px)] -top-[6px]"
            src="/assets/common/cross.svg"
            alt="cross"
          />
          <img
            className="absolute z-30 right-[calc(20px-5.5px)] md:right-[calc(40px-5.5px)] -top-[6px]"
            src="/assets/common/cross.svg"
            alt="cross"
          />
        </>
      )}
      {bottomCrosses && (
        <>
          <img
            className="absolute z-30 left-[calc(20px-5.5px)] md:left-[calc(40px-5.5px)] -bottom-[6px]"
            src="/assets/common/cross.svg"
            alt="cross"
          />
          <img
            className="absolute z-30 right-[calc(20px-5.5px)] md:right-[calc(40px-5.5px)] -bottom-[6px]"
            src="/assets/common/cross.svg"
            alt="cross"
          />
        </>
      )}
      <div className={clsx(className, `${maxW || "max-w-[1280px]"} mx-auto`)}>
        {children}
      </div>
    </div>
  );
};

export default Section;
