const BookCallModal = ({ isModalActive, setIsModalActive }) => {
  return (
    <div
      className={`bg-black fixed left-0 top-0 right-0 bottom-0 z-[60] bg-opacity-50 flex justify-center items-center ${
        isModalActive ? "block" : "hidden"
      }`}
    >
      <div className="w-[500px] max-w-[calc(100vw-40px)] h-[600px] max-h-[calc(100vh-40px)] overflow-auto bg-white p-5 rounded-lg shadow-lg">
        <div
          className="flex justify-end text-4xl cursor-pointer text-black"
          onClick={() => setIsModalActive(false)}
        >
          &times;
        </div>
        <iframe
          className={"h-[820px] sm:h-[780px] w-full"}
          title="calendly"
          src={`https://calendly.com/alphacode1786/30min`}
        />
      </div>
    </div>
  );
};

export default BookCallModal;
