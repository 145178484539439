import clsx from "clsx";

const Card = ({
  logo,
  img,
  date,
  idx,
  title,
  description,
  withLogo,
  withReadMoreButton,
}) => {
  return (
    <div
      className={clsx(
        "border border-solid rounded-3xl sm:rounded-[40px] p-5 sm:p-10 py-8 sm:py-14 md:p-[60px] bg-neutral-8 border-[#252134] hover:border-clrx transition-colors cursor-pointer relative",
        idx % 2 !== 0 && "lg:translate-y-24"
      )}
    >
      <img
        className="absolute left-0 right-0 top-0"
        src="/assets/landing/bg-project.svg"
        alt="bg"
      />
      <img className="mx-auto" src={img} alt={title} />

      <div className="mt-10">
        {date && (
          <div className="flex gap-3 mb-8">
            <span className="text-xs text-clrx font-space">[</span>
            <span className="text-xs text-neutral-3 font-space">{date}</span>
            <span className="text-xs text-clrx font-space">]</span>
          </div>
        )}
        {withLogo && <img className="mb-8 w-[114px]" src={logo} alt="" />}

        <h4 className="pb-4">{title}</h4>
        <p className="text-neutral-4">{description}</p>
      </div>
      {withReadMoreButton && (
        <button className="flex items-center gap-5 mt-8">
          <div className="text-xs font-bold font-mono">READ MORE</div>
          <img src="/assets/common/chevron-right.svg" alt="chevron" />
        </button>
      )}
    </div>
  );
};

export default Card;
