import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

const Accordion = ({ idx, defaultActive, title, description }) => {
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    if (defaultActive) setisActive(true);
  }, [defaultActive]);

  return (
    <div className="cursor-pointer" onClick={() => setisActive(!isActive)}>
      <div className="flex py-2 gap-5">
        <img src="/assets/landing/check.svg" alt="check" />
        {title}
      </div>

      <AnimatePresence>
        {isActive && (
          <motion.p
            key={"unique-step-" + idx}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            className="text-neutral-4 overflow-hidden"
          >
            {description}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
