import clsx from "clsx";
import Section from "components/common/Section";

const services = [
  {
    img: "/assets/services/service-1.png",
    title: "You Know What You Get",
    description:
      "We will always keep you up-to-date with what is happening in your project. The user stories and flows we create in Analysis & Scoping are our promise - a promise we deliver within a fixed price and time estimate. As the development process goes on, you will receive detailed reports on the time spent on different features and QA. Weekly calls with our project manager make sure that you are involved in development, every step of the way.",
  },
  {
    img: "/assets/services/service-2.png",
    title: "Lightning Speed",
    description:
      "We can achieve truly incredible speed of development that is faster than all of our competitors to bring your product to the market before you lose your competitive advantage. This is achieved through two main building blocks: agile development and the use of No Code/Low Code. Agile development means structuring tasks in iterations and launching a bare-bones version of the product first, adding non-essential features afterwards. This means a tangible, functional version of your app is launched significantly quicker.",
  },
  {
    img: "/assets/services/service-3.png",
    title: "No Code/Low Code",
    description:
      "We utilise No Code and Low Code, the latest innovation in the field of software development. This technology allows developers to build apps through a visual, drag-and-drop interface, which results in much quicker results and simpler quality assurance, by maintaining the final product as good as it would be if coded from scratch.",
  },
  {
    img: "/assets/services/service-4.png",
    title: "Built to Your Needs",
    description:
      "Custom software development has a few distinct advantages. It provides more robust solutions than off-the-shelf systems because it is designed for your particular organisation and is tailored exactly to your needs. In addition, custom software offers greater scalability, allowing businesses to easily add features and applications as they grow. Finally, custom software development ensures that you receive ongoing support from developers who are familiar with your system's inner workings and are able to quickly identify issues and solve them in a timely manner.",
  },
  {
    img: "/assets/services/service-5.png",
    title: "Customer-First",
    description:
      "Our development processes are designed specifically to suit your needs from start to finish. After determining the scope of the project according to your requirements, we continuously keep you in the loop until the project is over and make adjustments if they are ever needed. Every decision is made with the final user in mind. We only do things that bring value to you.",
  },
  {
    img: "/assets/services/service-6.png",
    title: "Post-Launch Support",
    description:
      "After completing a project, we provide technical assistance and maintenance services to troubleshoot any issues that may arise and ensure that your product is working correctly. This could include diagnosing problems, responding to user feedback, performing workflow reviews, monitoring system performance, and more. This also involves continuing to make improvements and updates to the product over time so that it remains up-to-date with changing technologies and industry trends. With regular post-launch support, we help customers get the most out of their products without them having to invest additional resources in developing new versions.",
  },
];

const ServicesList = () => {
  return (
    <Section className="py-[160px]" leftLine rightLine>
      <div className="text-center">
        <h1 className="mb-3">What Is Custom Software Development?</h1>
        <p className="sm:text-lg">
          Custom software development is the process of designing, creating,
          deploying, and maintaining software applications for specific business
          needs. It allows organisations to tailor their technology solutions to
          meet the unique requirements of their businesses. This process
          involves careful analysis of your specific needs, determining the best
          way to address those needs with custom-built software, and then
          creating and deploying the solution.
        </p>
      </div>

      <div className="mt-[160px]">
        {services.map((el, idx) => {
          return (
            <div
              key={"service-" + idx}
              className={clsx(
                "grid lg:grid-cols-2 gap-8 2xl:gap-[112px] relative",
                idx !== services.length - 1 && "mb-12 lg:mb-[100px]"
              )}
            >
              <img
                className="absolute -left-[20%] -top-[20%] -z-10 opacity-50 w-[50%]"
                src="/assets/landing/rodmap-bg.png"
                alt="roadmap"
              />
              <img
                className={clsx(
                  "rounded-3xl",
                  "w-full lg:w-auto relative",
                  idx % 2 !== 0 && "md:ml-auto lg:ml-0"
                )}
                src={el.img}
                alt={el.title}
              />

              <div
                className={clsx(
                  idx % 2 !== 0 && "lg:-order-1",
                  "relative flex items-center"
                )}
              >
                <div>
                  <div className="flex gap-3 mb-8">
                    <span className="text-xs text-clrx font-space">[</span>
                    <span className="text-xs text-neutral-3 font-space">
                      No. {("0" + (idx + 1)).slice(-2)}
                    </span>
                    <span className="text-xs text-clrx font-space">]</span>
                  </div>

                  <h2 className="my-6">{el.title}</h2>

                  <p className="text-neutral-3">
                    We utilise No Code and Low Code, the latest innovation in
                    the field of software development. This technology allows
                    developers to build apps through a visual, drag-and-drop
                    interface, which results in much quicker results and simpler
                    quality assurance, by maintaining the final product as good
                    as it would be if coded from scratch.
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ServicesList;
