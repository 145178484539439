import Section from "components/common/Section";

const Trust = () => {
  return (
    <Section
      className="pt-[160px] lg:pt-[220px] pb-24"
      rootClassName="relative"
      leftLine
      rightLine
    >
      <img
        className="hidden md:block absolute -left-[15%] xl:left-0 top-1/2 -translate-y-1/2 h-full"
        src="/assets/landing/bg-bot.png"
        alt="bg"
      />
      <div className="relative flex flex-col-reverse lg:flex-row justify-end items-center border border-solid border-[#FFFFFF1C] rounded-[24px] px-5 sm:px-10 py-8 md:px-24 sm:pb-0 sm:p-16 md:pt-28 pb-0 lg:pb-28 xl:py-48">
        <img
          className="lg:absolute bottom-0 left-0 w-[300px] lg:w-[50%] xl:w-[65%]"
          src="/assets/landing/bot.png"
          alt="bot"
        />

        <div className="lg:w-[340px]">
          <h4 className="mb-6">Why Clients Trust Us</h4>

          {/* <p className="text-[#B6B1C8] lg:text-justify">
            
          </p> */}

          <p className="text-[#B6B1C8] lg:text-left">
            VIE stands for Value, Integrity, and Excellence
          </p>
          <br />
          <h4>Value</h4>
          <br />
          <p className="text-[#B6B1C8] lg:text-left flex flex-col gap-5">
            <strong className="text-white"> Cost-Effective Solutions:</strong> Offering competitive
            pricing without compromising on quality, ensuring clients receive
            the best return on their investment.
            <strong className="text-white">Comprehensive Services:</strong>
            Providing a wide range of services to meet all client needs, adding
            significant value through convenience and comprehensive support.
            <strong className="text-white">Long-Term Benefits:</strong>
            Focusing on long-term success and sustainability, helping clients
            achieve enduring results and growth.
            <strong className="text-white">Integrity Transparent Practices:</strong>
            Maintaining honesty and transparency in all our dealings, ensuring
            clients are always well-informed and confident in our services.
            <strong className="text-white">Ethical Standards:</strong>
            Adhering to the highest ethical standards, ensuring trust and
            credibility in every interaction.
            <strong className="text-white">Accountability:</strong>
            Taking full responsibility for our actions and decisions,
            consistently delivering on our promises and commitments.
            <strong className="text-white">Excellence Uncompromising Quality:</strong>
            Committing to excellence in every aspect of our work, from the
            smallest details to the most significant projects.
            <strong className="text-white">Skilled Professionals:</strong>
            Employing a team of highly skilled and knowledgeable professionals
            dedicated to achieving excellence in their respective fields.
            <strong className="text-white">Continuous Improvement:</strong>
            Striving for continuous improvement and innovation, always seeking
            ways to enhance our services and exceed client expectations.
          </p>
        </div>
      </div>
    </Section>
  );
};

export default Trust;
