import Section from "./Section";

const Hero = ({ title, description }) => {
  return (
    <Section
      className="relative z-10 overflow-hidden"
      maxW="max-w-[100%]"
      bottomCrosses
      bottomLine
      leftLine
      rightLine
    >
      <img
        class="absolute w-full object-contain left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        src="assets/common/hero-gradient.png"
        alt=""
      />
      <img
        className="absolute h-[110%] max-w-none left-1/2 top-1/2 -translate-x-1/2 translate-y-[calc(-50%-40px)]"
        src="assets/common/bg-hero.svg"
        alt=""
      />
      <div className="py-16 min-h-[400px] md:h-screen flex items-center justify-center relative">
        <div className="w-full max-w-[992px] text-center">
          <h1 className="mb-3">{title}</h1>
          {description && (
            <p className="text-neutral-2 sm:text-lg">{description}</p>
          )}
        </div>
      </div>
    </Section>
  );
};

export default Hero;
