import Section from "components/common/Section";
import React from "react";

const WhatWeDo = () => {
  return (
    <Section leftLine rightLine className="pb-[160px]">
      <img
        className="absolute -left-[20%] -top-[20%] -z-10 opacity-50 w-[50%]"
        src="/assets/landing/rodmap-bg.png"
        alt="roadmap"
      />
      <div className="grid lg:grid-cols-2 gap-20 2xl:gap-[112px]">
        <div className="flex items-center">
          <div className="">
            <h2 className="mb-6">What We Do</h2>

            <p className="text-neutral-3">
              We provide high quality and customer-focused IT services: Analysis
              and Scoping, Product Design, Custom Software Development and
              Native App Development. Our processes have been polished over the
              years to bring you the best that Agile development and No Code
              have to offer. <br />
              <br />
              Idea Link has already helped a wide variety of businesses all over
              the world, ranging from SaaS startups to long-established
              corporations: travel agencies, telecommunication and legal firms,
              online entertainment companies, manufacturers, online course
              providers and more. <br />
              <br />
              The philosophy that guides us is one of care - your project is not
              just a task list to us. We get deeply involved every step of the
              way. You can count on us being your partners because we want to
              share your vision and build the right product for you and your
              customers for the long-term.
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <img
            className="rounded-3xl border border-solid border-neutral-1"
            src="/assets/about-us/what-we-do.png"
            alt="who"
          />
        </div>
      </div>
    </Section>
  );
};

export default WhatWeDo;
