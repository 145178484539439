import Hero from "components/common/Hero";
import KeyBenefits from "components/Services/KeyBenefits";
import WhyUs from "components/Services/WhyUs";
import ServicesList from "components/Services/ServicesList";
import OtherServices from "components/Services/OtherServices";

const Services = () => {
  return (
    <>
      <Hero title="Our Services" />
      <ServicesList />
      <KeyBenefits />
      <WhyUs />
      <OtherServices />
    </>
  );
};

export default Services;
