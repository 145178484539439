import Hero from "components/common/Hero";
import BlogsList from "components/Blog/BlogsList";
import Services from "components/common/Services";

const Blog = () => {
  return (
    <>
      <Hero
        title="We can build anything together."
        description="With years of experience launching software for enterprises and startups alike, we can provide you with a platform to scale your business, built multiple times faster than with traditional software development. Simply come with an idea and a goal, we will take care of the rest. Want inspiration? Take a look at some examples below."
      />
      <BlogsList blogsSection withReadMoreButton />
      <Services noPt pb />
    </>
  );
};

export default Blog;
