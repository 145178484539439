import clsx from "clsx";

import classes from "./ServiceCard.module.css";

const ServiceCard = ({ title, description, idx }) => {
  return (
    <div
      className={clsx(
        "h-full relative group cursor-pointer",
        classes.serviceCard
      )}
    >
      <div className="bg-neutral-8 flex flex-col justify-between h-full p-5 py-8 sm:p-10 relative z-10 overflow-hidden">
        <img
          className="group-hover:opacity-100 transition-all opacity-0 absolute left-0 top-0 w-full h-full object-cover"
          src="/assets/landing/card-bg.png"
          alt="card-bg"
        />
        <div className="relative">
          <h5 className="mb-5">{title}</h5>
          <p className="text-neutral-3">{description}</p>
        </div>

        <div className="flex justify-between items-center pt-10 relative">
          <div className="w-12 h-12 bg-neutral-4 rounded-xl font-mono text-neutral-8 flex justify-center items-center text-base font-bold">
            {("0" + (idx + 1)).slice(-2)}
          </div>

          {/* <button className="flex items-center gap-5">
            <div className="text-xs font-bold font-mono">READ MORE</div>
            <img src="/assets/common/chevron-right.svg" alt="chevron" />
          </button> */}
        </div>
      </div>
      <div className="w-[52px] h-[52px] rounded-xl absolute transition-all right-6 top-6 group-hover:right-2 group-hover:top-2 bg-clrx"></div>
    </div>
  );
};

export default ServiceCard;
