import Section from "components/common/Section";
import React from "react";

const We = () => {
  return (
    <Section leftLine rightLine className=" ">
      <div className="grid lg:grid-cols-2 gap-20 2xl:gap-[112px]">
        <div className="flex items-center">
          <img
            className="rounded-3xl border border-solid border-neutral-1"
            src="/assets/about-us/who-we-are.png"
            alt="who"
          />
        </div>
        <div className="flex items-center">
          <div className="">
            <h2 className="mb-6">Who We Are</h2>

            <p className="text-neutral-3">
              We are an agile and open-minded company that understands the
              challenges of startups, scaling a business and how the right
              software can propel companies to new heights. <br />
              <br /> We are a team of misfits: basketball players turned
              developers, architects turned designers, HRs turned marketers. We
              believe that individual thought, ownership and honesty are what
              turn ordinary people into drivers of change. <br />
              <br /> We have no walls, no set hours and no prejudices in the
              office - just a bunch of professionals who are passionate about
              what they do for a living. We're here because we love building
              products that create value for our clients and help drive their
              businesses forward.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default We;
