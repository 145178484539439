import Section from "components/common/Section";
import ButtonGhost from "components/common/ButtonGhost";
import Accordion from "components/common/Accordion";

const items = [
  {
    title: "Technology Know-How",
    description:
      "We’re expert users of Bubble, Webflow, Xano, Flutterflow, Thunkable, and other No Code tools. We’re also developers - if any limitations arise with the tools - we’ll solve them with custom code.",
  },
  {
    title: "Smart Automation",
    description:
      "We’re expert users of Bubble, Webflow, Xano, Flutterflow, Thunkable, and other No Code tools. We’re also developers - if any limitations arise with the tools - we’ll solve them with custom code.",
  },
  {
    title: "Top-notch Security",
    description:
      "We’re expert users of Bubble, Webflow, Xano, Flutterflow, Thunkable, and other No Code tools. We’re also developers - if any limitations arise with the tools - we’ll solve them with custom code.",
  },
];

const Unique = () => {
  return (
    <Section
      className="pt-[160px] pb-[100px] overflow-hidden"
      topCrosses
      topLine
      bottomCrosses
      bottomLine
      leftLine
      rightLine
    >
      <img
        className="absolute top-1/2 -translate-y-1/2 right-0 -z-10"
        src="/assets/landing/bg-unique.png"
        alt=""
      />
      <div className="grid lg:grid-cols-2 gap-3 lg:gap-[60px]">
        <div className="pb-[60px] relative">
          <h2>
            Why We <br className="hidden lg:block" /> Are Unique
          </h2>

          <div className="mt-8 mb-12 flex flex-col gap-3">
            {items.map((el, idx) => {
              return (
                <Accordion
                  idx
                  key={"unique" + idx}
                  defaultActive={idx === 0}
                  {...el}
                />
              );
            })}
          </div>

          <ButtonGhost>TRY IT NOW</ButtonGhost>
        </div>

        <div className="relative">
          <div className="max-w-[590px] lg:max-w-[360px] lg:mx-auto text-neutral-4">
            We use No Code and Low Code technologies & integrated
            development-design teams to build advanced software up to 5x times
            faster.
          </div>

          <img
            className="hidden lg:block absolute bottom-0 max-w-none w-[150%] 2xl:w-[170%] right-0"
            src="/assets/landing/unique.svg"
            alt="unique"
          />

          <img
            className="block lg:hidden mt-10 mx-auto lg:mx-0"
            src="/assets/landing/unique-min.svg"
            alt="unique"
          />
        </div>
      </div>
    </Section>
  );
};

export default Unique;
