import Hero from "components/Home/Hero";
import Projects from "components/common/Projects";
import Roadmap from "components/Home/Roadmap";
import Services from "components/common/Services";
import Team from "components/common/Team";
import Testimonials from "components/Home/Testimonials";
import Trust from "components/Home/Trust";
import Unique from "components/Home/Unique";

const Home = () => {
  return (
    <>
      <Hero />
      <Services />
      <Trust />
      <Unique />
      <Roadmap />
      <Testimonials />
      <Projects />
      <Team />
    </>
  );
};

export default Home;
