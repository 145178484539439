import BookCallModal from "components/common/BookCallModal";
import ButtonPrimary from "components/common/ButtonPrimary";
import Section from "components/common/Section";
import { useState } from "react";
import { Link } from "react-router-dom";

const links = [
  {
    label: "OUR SERVICES",
    to: "/services",
  },
  {
    label: "OUR WORK",
    to: "/work",
  },
  {
    label: "ABOUT US",
    to: "/about-us",
  },
  {
    label: "BLOG",
    to: "/blog",
  },
];

const socials = [
  {
    icon: "/assets/common/dc.svg",
    link: "https://google.com/",
  },
  {
    icon: "/assets/common/twitter.svg",
    link: "https://google.com/",
  },
  {
    icon: "/assets/common/insta.svg",
    link: "https://google.com/",
  },
  {
    icon: "/assets/common/tele.svg",
    link: "https://google.com/",
  },
  {
    icon: "/assets/common/fb.svg",
    link: "https://google.com/",
  },
];

const Footer = () => {
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <BookCallModal
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
      />

      <Section
        // rootClassName="-z-10"
        className=""
        topLine
        leftLine
        rightLine
        topCrosses
        bottomCrosses
      >
        {/* <img
          className="absolute pointer-events-none left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
          src="/assets/common/bg-footer.png"
          alt=""
        />
        <img
          className="absolute pointer-events-none left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          src="/assets/common/bg-footer.svg"
          alt=""
        />
        <img
          className="absolute pointer-events-none scale-50 xl:scale-100 sm:left-36 -top-10"
          src="/assets/common/footer-puzzle-1.svg"
          alt="puzzle"
        />
        <img
          className="absolute pointer-events-none scale-50 xl:scale-100 sm:right-0 -bottom-20"
          src="/assets/common/footer-puzzle-2.svg"
          alt="puzzle"
        /> */}
        {/* <div className="py-[140px] relative z-10">
          <div className="max-w-[690px] mx-auto">
            <h1 className="text-center leading-normal mb-3">
              Don't Hesitate{" "}
              <span className="relative">
                Contact Us{" "}
                <img
                  className="absolute left-1/2 -translate-x-1/2 -bottom-1"
                  src="/assets/common/underline-2.svg"
                  alt="underline"
                />{" "}
              </span>
            </h1>
            <p className="text-neutral-4 text-center">
              We will provide you with a price and time estimate for{" "}
              <br className="hidden md:block" /> development after the call!
            </p>

            <div className="mt-6 flex justify-center">
              <ButtonPrimary onClick={() => setIsModalActive(true)}>
                BOOK A CALL
              </ButtonPrimary>
            </div>
          </div>
        </div> */}
      </Section>
      <div className="px-5 md:px-10 relative">
        <div className="pt-6 py-4 sm:pt-16 md:pt-[100px] flex flex-col md:flex-row justify-between items-center border-x border-solid border-stroke-1 relative">
          <div className="absolute top-0 left-0 z-20 h-[1px] w-full border-t border-solid border-stroke-1"></div>
          <Link to="/">
            <img
              className="mb-6 sm:mb-0 w-[140px]"
              src="/assets/common/logo.png"
              alt="logo"
            />
          </Link>
          <div className="flex flex-col sm:flex-row items-center">
            {links.map((el, idx) => {
              return (
                <Link
                  key={"footer-link" + idx}
                  to={el.to}
                  className="text-xs font-semibold font-mono block py-3 sm:py-8 px-6 xl:px-12 text-neutral-1.1 hover:text-white transition-all"
                >
                  {el.label}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-6 sm:gap-0 justify-between sm:items-center mx-5 md:mx-10 py-6 border-x border-t border-solid border-stroke-1">
        <div className="text-center sm:text-left text-sm text-neutral-4">
          &copy; 2023 UI8
        </div>

        {/* <div className="flex gap-2 justify-between sm:justify-start sm:gap-8 items-center">
          {socials.map((el, idx) => {
            return (
              <Link
                key={"social-link" + idx}
                to={el.to}
                className="w-10 h-10 rounded-[50%] bg-neutral-7 transition-all hover:scale-110 flex justify-center items-center"
              >
                <img src={el.icon} alt="social" />
              </Link>
            );
          })}
        </div> */}
      </div>
    </>
  );
};

export default Footer;
