import { Routes, Route } from "react-router-dom";

import ScrollToTop from "components/common/ScrollToTop";
import MainLayout from "layout/MainLayout";
import Home from "pages/Home";
import Services from "pages/Services";
import Work from "pages/Work";
import Blog from "pages/Blog";
import AboutUs from "pages/AboutUs";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="work" element={<Work />} />
          <Route path="blog" element={<Blog />} />
          <Route path="services" element={<Services />} />
          <Route path="about-us" element={<AboutUs />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
