import { Link, NavLink } from "react-router-dom";

import ButtonGhost from "components/common/ButtonGhost";
import { useEffect, useState } from "react";
import clsx from "clsx";
import BookCallModal from "components/common/BookCallModal";

const navItems = [
  {
    label: "OUR SERVICES",
    to: "/services",
  },
  {
    label: "OUR WORK",
    to: "/work",
  },
  {
    label: "ABOUT US",
    to: "/about-us",
  },
  {
    label: "BLOG",
    to: "/blog",
  },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);

  useEffect(() => {
    const ev = () => {
      const scroll = window.pageYOffset;
      if (scroll > 10) setIsScrolled(true);
      else setIsScrolled(false);
    };

    window.addEventListener("scroll", ev);

    return () => {
      return window.removeEventListener("scroll", ev);
    };
  });

  return (
    <>
      <BookCallModal
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
      />
      <div
        className={clsx(
          "w-full h-full top-0 fixed bg-black opacity-50 z-50 block lg:hidden transition-all",
          isMenuActive ? "right-0" : "-right-full"
        )}
        onClick={() => setIsMenuActive(false)}
      ></div>
      <div
        className={clsx(
          "fixed left-0 w-full top-0 z-50 flex justify-between items-center border-b border-solid border-neutral-1 h-[84px] px-5 md:px-10",
          isScrolled ? "bg-neutral-8" : "bg-transparent"
        )}
      >
        <Link to="/">
          <img className="w-[140px]" src="/assets/common/logo.png" alt="logo" />
        </Link>

        <div
          className={clsx(
            "w-[320px] lg:w-auto bg-neutral-7 lg:bg-transparent px-10 lg:px-0 py-20 lg:py-0 fixed top-0 lg:static h-screen lg:h-auto flex flex-col lg:flex-row items-start lg:items-center gap-6 xl:gap-12 2xl:gap-24 transition-all",
            isMenuActive ? "right-0" : "-right-[320px]"
          )}
        >
          {navItems.map((el, idx) => {
            return (
              <NavLink
                key={"nav-item-" + idx}
                to={el.to}
                className="text-neutral-1.1 font-mono hover:text-white transition-all"
                onClick={() => setIsMenuActive(false)}
              >
                {el.label}
              </NavLink>
            );
          })}

          <div className="block lg:hidden">
            <ButtonGhost onClick={() => setIsModalActive(true)}>
              BOOK A CALL
            </ButtonGhost>
          </div>

          <div
            className="block lg:hidden text-3xl absolute top-5 right-10"
            onClick={() => setIsMenuActive(!isMenuActive)}
          >
            &times;
          </div>
        </div>

        <div className="hidden lg:block">
          <ButtonGhost onClick={() => setIsModalActive(true)}>
            BOOK A CALL
          </ButtonGhost>
        </div>

        <div className="block lg:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => setIsMenuActive(!isMenuActive)}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Navbar;
