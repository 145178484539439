import Hero from "components/common/Hero";
import Mission from "components/AboutUs/Mission";
import We from "components/AboutUs/We";
import Values from "components/AboutUs/Values";
import Team from "components/common/Team";
import WhatWeDo from "components/AboutUs/WhatWeDo";
import Services from "components/common/Services";

const AboutUs = () => {
  return (
    <>
      <Hero title="About Us" />
      <Mission />
      <We />
      <Values />
      <WhatWeDo />
      <Team title="The Team Behind Every Idea" />
      <Services noPt pb />
    </>
  );
};

export default AboutUs;
