import Section from "components/common/Section";
import ServiceCard from "components/common/ServiceCard";

const otherServices = [
  {
    title: "Native App Development",
    description:
      "Launch a native iOS & Android app built to your exact requirements with lightning speed.",
  },
  {
    title: "Product Design",
    description:
      "Full Figma designs and a clickable prototype of your software, so you know exactly how it will look and feel.",
  },
  {
    title: "Analysis & Scoping",
    description:
      "Determine the necessary features, database diagrams, user stories and flows of your product to simplify development.",
  },
];

const OtherServices = () => {
  return (
    <Section leftLine rightLine className="pb-[160px]">
      <h2 className="text-center">Other Services We Provide</h2>

      <div className="mt-20">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-12">
          {otherServices.map((el, idx) => {
            return (
              <ServiceCard key={"other-service" + idx} {...el} idx={idx} />
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default OtherServices;
