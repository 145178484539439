import BookCallModal from "components/common/BookCallModal";
import ButtonPrimary from "components/common/ButtonPrimary";
import Section from "components/common/Section";
import { useState } from "react";

const clients = [
  "/assets/landing/sprinklr.webp",
  "/assets/landing/outbrain.png",
  "/assets/landing/netapp.webp",
  "/assets/landing/jfrog.webp",
  "/assets/landing/client.png",
];

const Hero = () => {
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <BookCallModal
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
      />
      <div className="relative">
        <img
          className="hidden md:block absolute w-full object-contain left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          src="assets/common/hero-gradient.png"
          alt=""
        />
        <img
          className="hidden md:block absolute max-w-[1440px] w-full object-contain left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          src="assets/common/bg-hero.svg"
          alt=""
        />
        <Section
          rootClassName="pt-16 sm:pt-[100px]"
          maxW="max-w-[992px]"
          leftLine
          rightLine
          bottomLine
          topCrosses
        >
          <div className="text-center">
            <h1 className="font-semibold mb-8">
              Custom Software Development Up to 5x Faster with No Code
            </h1>
            {/* <p className="text-neutral-2 sm:text-lg">
              Say goodbye to long development cycles. Discover how our
              innovative No Code & Low Code development services & polished
              processes can help you ship robust software fast.
            </p> */}

            <div className="mt-8 flex justify-center">
              <ButtonPrimary onClick={() => setIsModalActive(true)}>
                BOOK A CALL
              </ButtonPrimary>
            </div>

            {/* <img
              className="mt-[90px]"
              src="/assets/landing/hero.svg"
              alt="hero"
            /> */}

            <div className="mt-20 pb-6 md:mb-0">
              <div className="font-space text-base text-[#FFFFFF] opacity-50 mb-5 md:mb-0">
                OUR CLIENTS
              </div>
              {/* <p className="text-neutral-2 my-6 sm:text-lg">
              Say goodbye to long development cycles. Discover how our
              innovative No Code & Low Code development services & polished
              processes can help you ship robust software fast.
            </p> */}
              <div className="flex justify-center flex-wrap 2xl:flex-nowrap">
                {clients.map((el, idx) => {
                  return (
                    <div className="p-6 md:p-12" key={"client-" + idx}>
                      <img src={el} alt="client" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default Hero;
