import Section from "components/common/Section";

const Mission = () => {
  return (
    <Section className="py-[150px]" leftLine rightLine maxW="max-w-[1530px]">
      <img
        className="hidden lg:block absolute left-0 top-0 xl:w-[50%] -z-10"
        src="/assets/about-us/mission.png"
        alt="main"
      />
      <div className="border border-solid border-[#FFFFFF1C] rounded-3xl lg:pt-[112px] xl:py-[200px] flex flex-col-reverse xl:flex-row items-center justify-end pb-0 sm:pb-0 sm:p-16 px-5 sm:px-10 py-8 md:px-24 xl:p-24 relative">
        <img
          className="xl:absolute xl:-left-[3%] bottom-0 w-[300px] lg:w-[50%] xl:w-[calc(100%-595px-5rem)]"
          src="/assets/about-us/about-us-main.png"
          alt="about-us"
        />
        <div className="w-full max-w-[595px]">
          <h5 className="mb-8">
            We are a closely-knit team of creators, problem solvers and managers
            who all have one goal in mind - building software that facilitates
            business and is loved by its users. This is how we do it.
          </h5>

          <div className="mt-8 grid md:grid-cols-2 gap-10">
            <div>
              <h6 className="mb-3">Our Mission</h6>

              <p className="text-neutral-3">
                To develop custom software through strong ownership and the
                latest technologies, helping businesses move quicker.
              </p>
            </div>
            <div>
              <h6 className="mb-3">Our Mission</h6>

              <p className="text-neutral-3">
                To pave the way for No Code and Low Code to become the go-to
                choice for software development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Mission;
