import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import Section from "components/common/Section";
import { Link } from "react-router-dom";

const qaItems = [
  {
    question: "High Quality Software Development Services",
    answer: (
      <>
        At Idea Link, we employ experienced product developers and provide
        high-quality software development services to our clients. Our
        developers are highly knowledgeable in the latest technologies and have
        a proven track record of delivering successful projects.
        <br /> All of the software that we develop is built with secure and
        reliable tools that adhere to industry standards. Furthermore, our team
        uses best practices in software engineering to ensure that each project
        meets the highest standards for quality assurance.
      </>
    ),
  },
  {
    question: "Rapid Turnaround Times",
    answer: (
      <>
        At Idea Link, we employ experienced product developers and provide
        high-quality software development services to our clients. Our
        developers are highly knowledgeable in the latest technologies and have
        a proven track record of delivering successful projects.
        <br /> All of the software that we develop is built with secure and
        reliable tools that adhere to industry standards. Furthermore, our team
        uses best practices in software engineering to ensure that each project
        meets the highest standards for quality assurance.
      </>
    ),
  },
  {
    question: "Customer-First Approach",
    answer: (
      <>
        At Idea Link, we employ experienced product developers and provide
        high-quality software development services to our clients. Our
        developers are highly knowledgeable in the latest technologies and have
        a proven track record of delivering successful projects.
        <br /> All of the software that we develop is built with secure and
        reliable tools that adhere to industry standards. Furthermore, our team
        uses best practices in software engineering to ensure that each project
        meets the highest standards for quality assurance.
      </>
    ),
  },
  {
    question: "Cost-Effective Solutions",
    answer: (
      <>
        At Idea Link, we employ experienced product developers and provide
        high-quality software development services to our clients. Our
        developers are highly knowledgeable in the latest technologies and have
        a proven track record of delivering successful projects.
        <br /> All of the software that we develop is built with secure and
        reliable tools that adhere to industry standards. Furthermore, our team
        uses best practices in software engineering to ensure that each project
        meets the highest standards for quality assurance.
      </>
    ),
  },
  {
    question: "Accountability",
    answer: (
      <>
        At Idea Link, we employ experienced product developers and provide
        high-quality software development services to our clients. Our
        developers are highly knowledgeable in the latest technologies and have
        a proven track record of delivering successful projects. <br />
        <br /> All of the software that we develop is built with secure and
        reliable tools that adhere to industry standards. Furthermore, our team
        uses best practices in software engineering to ensure that each project
        meets the highest standards for quality assurance.
      </>
    ),
  },
];

const WhyUs = () => {
  return (
    <Section className="py-24 md:py-[160px]" leftLine rightLine>
      <div className="grid md:grid-cols-12 gap-6">
        <div className="col-span-5">
          <h2 className="mb-6">Why Us</h2>
          <p className="text-neutral-3 overflow-hidden">
            Haven’t found what you’re looking for?{" "}
            <Link className="text-white" to="/">
              Contact us
            </Link>
          </p>
        </div>
        <div className="col-span-7">
          {qaItems.map((el, idx) => {
            return (
              <Item key={"qa-item-" + idx} {...el} defaultOpen={idx === 0} />
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default WhyUs;

const Item = ({ question, answer, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) setIsOpen(true);
  }, [defaultOpen]);

  return (
    <div className="py-8 border-b border-b-solid border-stroke-1">
      <div
        className="flex justify-between items-start gap-8 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-xl">{question}</div>
        {isOpen ? (
          <img src="/assets/common/minus.svg" alt="minus" />
        ) : (
          <img src="/assets/common/plus.svg" alt="plus" />
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="text-neutral-3 overflow-hidden"
            initial={{ height: 0, marginTop: 0 }}
            animate={{ height: "auto", marginTop: 24 }}
            exit={{ height: 0, marginTop: 0 }}
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
