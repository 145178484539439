import Section from "components/common/Section";

const KeyBenefits = () => {
  return (
    <Section leftLine rightLine>
      <h2 className="text-center mb-20">Key Benefits</h2>

      <div className="grid md:grid-cols-2 gap-5">
        <div className="border border-solid border-neutral-1 rounded-3xl bg-benefit bg-cover bg-bottom flex items-end">
          <div className="p-5 py-8 lg:p-[60px]">
            <div className="w-full">
              <h4 className="mb-4">Faster than Tradiitional Development</h4>

              <p className="text-neutral-4">
                With us, you get the same results, usability and scalability as
                with agencies that utilise traditional software development, but
                multiple times faster due to the groundbreaking No Code/Low Code
                technology that we use.
              </p>
            </div>
          </div>
        </div>
        <div className="p-4 bg-neutral-7 rounded-3xl">
          <div className="px-2 md:p-5 py-8 md:py-8 lg:p-12 mb-5 md:mb-20">
            <h4 className="mb-4">You Are In Control</h4>

            <p className="text-neutral-4">
              With a constant stream of communication from us, you will always
              know where your project is and how our time is spent. With weekly
              calls, you are involved in our decision making, so you will know
              exactly what you’ll get.
            </p>
          </div>

          <div>
            <img
              className="w-full"
              src="/assets/services/benefit-2.png"
              alt="benefit"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default KeyBenefits;
