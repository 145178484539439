import { Link } from "react-router-dom";

import Section from "components/common/Section";
import Card from "components/common/Card";

const blogs = [
  {
    date: "September, 2023",
    img: "/assets/blog/blog-1.png",
    title: "Outsourcing Software Development: No Code For Shorter TTM",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-2.png",
    title: "Enterprise No Code Software - Worth Doing the Work Yourself?",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-4.png",
    title: "9 Coolest No Code Startups",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-5.png",
    title: "The 13 Best No Code Tools in 2022",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-6.png",
    title: "3 Reasons Why Bubble is Our Favourite No Code Tool",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-7.png",
    title: "What is No Code? Your New Super Power",
  },
  {
    date: "September, 2023",
    img: "/assets/blog/blog-8.png",
    title: "Software Development Services Don't Have to Be Expensive",
  },
];

const BlogsList = () => {
  return (
    <Section leftLine rightLine className="py-24 md:py-[160px]">
      <div className="lg:pb-24 relative z-10">
        <div className="grid lg:grid-cols-2 gap-6">
          {blogs.map((el, idx) => {
            return (
              <Link to="/">
                <Card
                  key={"project-" + idx}
                  idx={idx}
                  {...el}
                  withReadMoreButton
                />
              </Link>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default BlogsList;
