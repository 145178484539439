import { Splide, SplideSlide } from "@splidejs/react-splide";

import Section from "components/common/Section";

const testimonials = [
  {
    name: "Jamil Lynch",
    designation: "Founder",
    comment:
      "Kyle quickly understood our unique challenges, and in a very short time, created a powerful, customized solution that brought diverse details of our business together in one easy-to-manage interface.",
    img: "/assets/landing/testimonial-1.png",
    company: "/assets/landing/sprinklr.webp",
  },
  {
    name: "Gwen Hall",
    designation: "CEO Open Infra US",
    comment:
      "When you only have a vision of how you would like to facilitate your processes, call Minh for help. Minh sincerely delved into the problem, our vision, devoted a lot of time to refine the details with our team who will use the product.",
    img: "/assets/landing/testimonial-2.png",
    company: "/assets/landing/netapp.webp",
  },
  {
    name: "Anais Rice",
    designation: "UX Designer",
    comment:
      "I was blown away by the accuracy and speed of the AI chatbot on Brainwave. It was able to understand my queries and provide relevant recommendations in seconds.",
    img: "/assets/landing/testimonial-3.png",
    company: "/assets/landing/outbrain.png",
  },
];

const Testimonials = () => {
  return (
    <>
      <Section leftLine rightLine className="pt-[160px]">
        <img
          className="mx-auto"
          src="/assets/landing/testimonial.svg"
          alt="testimonial"
        />

        <div>
          <div className="text-center">
            <div className="text-neutral-3 text-xs font-space">
              [ READY TO GET STARTED ]
            </div>

            <h2 className="mt-4">What the community is saying</h2>
          </div>
        </div>
      </Section>

      <div className="pt-20 pl-10 relative">
        <div className="absolute left-5 md:left-10 top-0 h-full w-[1px] border-l border-solid border-stroke-1"></div>

        <div className="absolute right-5 md:right-10 top-0 h-full w-[1px] border-r border-solid border-stroke-1"></div>

        <div className="2xl:mt-20 ml-auto sm:max-w-[calc(1280px+(100vw-1280px)/2)]">
          <Splide
            options={{
              arrows: false,
              gap: 48,
              autoWidth: true,
              dots: false,
            }}
          >
            {testimonials.map((el, idx) => {
              return (
                <SplideSlide key={"testimonial-item-" + idx}>
                  <div className="md:h-full w-[calc(100vw-80px)] lg:w-[740px] relative sm:rounded-none rounded-3xl overflow-hidden sm:overflow-visible">
                    <img
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover md:object-contain opacity-50 md:opacity-100"
                      src={el.img}
                      alt="bg"
                    />
                    <div className="relative md:h-full border border-solid border-[#75718580] rounded-3xl sm:p-4 overflow-hidden">
                      <div className="md:h-full flex flex-col md:flex-row justify-between">
                        <div className="md:w-1/2 md:h-full flex-shrink-0 flex gap-10 flex-col justify-between px-5 sm:px-6 py-12">
                          <img
                            className="w-[154px]"
                            src={el.company}
                            alt="remark"
                          />

                          <div>
                            <h5>{el.name}</h5>
                            <h5 className="text-neutral-4">{el.designation}</h5>
                          </div>
                        </div>
                        <div className="md:w-1/2 bg-neutral-8 md:h-full flex-shrink-0 border border-solid border-[#FFC876] p-5 sm:p-8 rounded-2xl">
                          <div className="sm:text-lg font-mono">
                            {el.comment}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
