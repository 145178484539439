import clsx from "clsx";
import Section from "components/common/Section";

const devs = [
  {
    img: "/assets/landing/dev-1.jpg",
    name: "Minh Nguyen",
    designation: "Co-Founder, Business Developer",
    cornerClr: "neutral-4",
  },
  {
    img: "/assets/landing/kyle.jpg",
    name: "Kyle Grainger",
    designation: "Co-Founder, Lead Developer",
    cornerClr: "clrx",
  },
  {
    img: "/assets/landing/Pedram.jpeg",
    name: "Pedram Ashkani",
    designation: "Full Stack Developer",
    cornerClr: "neutral-4",
  },
  {
    img: "/assets/landing/omar.jpeg",
    name: "Omar Afridi",
    designation: "Prompt Engineer, Golang Specialist",
    cornerClr: "neutral-4",
  },
];

const Team = ({ title }) => {
  return (
    <Section leftLine rightLine className="pb-[160px]">
      <div className="text-center">
        <div className="flex justify-center items-center gap-3">
          <span className="text-clrx text-xs">[</span>
          <span className="text-neutral-3 text-xs font-space">
            Meet the VIEAI Team
          </span>
          <span className="text-clrx text-xs">]</span>
        </div>

        <h2 className={clsx("mt-4 mx-auto", !title && "max-w-[477px]")}>
          {title || "Built by developers, for developers."}
        </h2>
      </div>

      <div className="mt-20">
        <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-11">
          {devs.map((el, idx) => {
            return (
              <div key={"dev-" + idx} className="relative">
                <div
                  className={clsx(
                    `absolute right-1 top-1 w-[60px] h-[60px] rounded-2xl bg-${el.cornerClr}`
                  )}
                ></div>
                <div className="p-2 h-full pb-10 rounded-3xl rounded-tr-[70px] bg-[#222325] relative">
                  <img
                    className="rounded-[20px] md:h-[330px] object-cover rounded-tr-[70px] w-full"
                    src={el.img}
                    alt={el.name}
                  />
                  <div className="mt-10 px-[26px]">
                    <div className="font-inter text-2xl font-black">
                      {el.name}
                    </div>
                    <div className="mt-1 font-karla text-neutral-5">
                      {el.designation}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default Team;
