import clsx from "clsx";

import Accordion from "components/common/Accordion";
import "./AboutUs.css";

const items = [
  {
    title: "Ownership",
    description:
      "We listen to our clients in order to understand their business needs more deeply. We view ourselves as a partner rather than just a vendor, which helps us develop unique solutions tailored to their exact needs.",
  },
  {
    title: "Transparency",
    description:
      "We listen to our clients in order to understand their business needs more deeply. We view ourselves as a partner rather than just a vendor, which helps us develop unique solutions tailored to their exact needs.",
  },
  {
    title: "Community",
    description:
      "We listen to our clients in order to understand their business needs more deeply. We view ourselves as a partner rather than just a vendor, which helps us develop unique solutions tailored to their exact needs.",
  },
  {
    title: "Innovation",
    description:
      "We listen to our clients in order to understand their business needs more deeply. We view ourselves as a partner rather than just a vendor, which helps us develop unique solutions tailored to their exact needs.",
  },
];

const Values = () => {
  return (
    <div className={clsx("py-[160px] relative")}>
      <div className="absolute z-20 left-5 md:left-10 top-0 h-full w-[1px] border-l border-solid border-stroke-1"></div>
      <div className="absolute z-20 right-5 md:right-10 top-0 h-full w-[1px] border-r border-solid border-stroke-1"></div>
      <div className="px-5 md:pl-10 xl:pr-0 ml-auto max-w-[calc(1280px+(100vw-1280px)/2)]">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-[112px]">
          <div className="max-w-[500px] lg:w-[366px] flex-shrink-0">
            <h2 className="max-w-[366px] mb-10">Our Values</h2>

            <div className="mb-12 flex flex-col gap-3">
              {items.map((el, idx) => {
                return (
                  <Accordion
                    idx
                    key={"unique" + idx}
                    defaultActive={idx === 0}
                    {...el}
                  />
                );
              })}
            </div>

            <div className="mt-11 flex justify-start gap-3">
              <img
                className="cursor-pointer"
                src="/assets/common/slider-round-btn.svg"
                alt="slider-btn"
              />
              <img
                className="cursor-pointer rotate-180"
                src="/assets/common/slider-round-btn.svg"
                alt="slider-btn"
              />
            </div>
          </div>
          <div className="border border-solid border-neutral-1 rounded-[40px] p-8 lg:p-0 max-w-[700px] mx-auto lg:mx-0 lg:max-w-none">
            <img
              className="w-full relative lg:top-[100px] lg:right-[100px]"
              src="/assets/about-us/values.svg"
              alt="values"
            />
          </div>
          {/* <div className={"no-overflow-slider"}>
            <Splide
              options={{
                arrows: false,
                gap: 24,
                autoWidth: true,
                type: "loop",
              }}
            >
              <SplideSlide>
                <div className="border border-solid border-neutral-1 rounded-[40px]">
                  <img
                    className="w-full relative top-[100px] right-[100px]"
                    src="/assets/about-us/values.svg"
                    alt="values"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="border border-solid border-neutral-1 rounded-[40px]">
                  <img
                    className="w-full relative top-[100px] right-[100px]"
                    src="/assets/about-us/values.svg"
                    alt="values"
                  />
                </div>
              </SplideSlide>
            </Splide>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Values;
